import api from '@/api'
import { LogsState, StudentsState } from '@/types'
import { getStudentsReports, getStudentsReviews } from '@/utils'
import { ActionContext } from 'vuex'

export default {
  namespaced: true,
  state: (): LogsState => ({
    student: {
      id: 0,
      created_at: '',
      first_name: '',
      last_name: '',
      github_id: '',
      discord_id: '',
      telegram_id: '',
      skype: '',
      type: '',
      updated_at: '',
      gender: 'unknown',
      logs: [],
      computed: {}
    },
    studentsReviews: [],
    studentsReports: [],
    studentLogs: []
  }),
  mutations: {
    init (state: LogsState, { data, params }: {data: StudentsState, params: {userId?: string, course?: string}}) {
      state.student = data.students[0] || state.student
      if (params.course) {
        state.studentsReviews = getStudentsReviews(data, params.course) || state.studentsReviews
      }
      if (params.course) {
        state.studentsReports = getStudentsReports(data, params.course) || state.studentsReports
      }
      state.studentLogs = data.studentsLogs || state.studentLogs
    }
  },
  actions: {
    async getLogs ({ commit, rootState }: ActionContext<LogsState, StudentsState>, params: {userId?: string, course?: string}) {
      const { data } = await api.get('admin/logs', { params })
      commit('init', { data: { ...data, config: (rootState.students as any).config }, params })
    }
  }
}

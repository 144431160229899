import { Mentor, Student, StudentFieldConfig, StudentLog, StudentReport, StudentWeeklyReportAnalysis, StudentsState } from './types'

// eslint-disable-next-line no-unused-vars
const flattenArray = <T = unknown>(acc: Array<T>, arr: Array<T>): Array<T> => [...acc, ...arr]

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Array<T> {
    last(): T;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.last = function () {
  return this[this.length - 1]
}

const mapValues = function (obj: any, callbackfn: any) {
  const objCopy = JSON.parse(JSON.stringify(obj))
  Object.keys(objCopy).forEach((key) => {
    objCopy[key] = typeof callbackfn === 'function' ? callbackfn((objCopy as any)[key]) : 'error'
  })
  return objCopy
}

export function getUsersLogs ({ students, studentsLogs, config }: StudentsState): Array<Student> {
  return students.map(student => ({
    ...student,
    logs: studentsLogs
      .filter(log => log.student_id === student.id)
      .sort((l1, l2) => l1.created_at.localeCompare(l2.created_at))
  }))
}

export function calculateFields (data: Array<Student>, fieldsConfig: Array<StudentFieldConfig>) {
  return data.map(student => ({
    ...student,
    ...fieldsConfig.reduce((acc: Record<string, string>, config) => ({
      ...acc,
      [config.value]: typeof config.computedValue === 'function' ? config.computedValue({ ...student, ...acc }) : 'Помилка калькуляції'
    }), {})
  }))
}

export function calculateHeaders (fieldsConfig: Array<StudentFieldConfig>) {
  return [...defaultStudentTableHeaders, ...fieldsConfig]
}

export function getAllStartedCourses (logs: StudentLog[] = []) {
  return [...new Set(logs.map(l => l.course))]
}

const STATUSES_ORDER = {
  selected: 0,
  started: 1,
  paused: 2,
  unpaused: 3,
  dropped: 4,
  stopped: 4,
  finished: 5
} as const

// eslint-disable-next-line no-unused-vars
function getLatestStatus (status1: keyof typeof STATUSES_ORDER, status2: keyof typeof STATUSES_ORDER) {
  return STATUSES_ORDER[status1] > STATUSES_ORDER[status2] ? status1 : status2
}

export function niceDate (datestr: string) {
  if (!datestr) {
    return ''
  }

  try {
    return new Date(datestr).toISOString().slice(0, 19).replace('T', ' ')
  } catch (e) {
    return 'Проблема з датою'
  }
}

export function humanDate (datestr: string, includeTime = true) {
  if (!datestr) {
    return ''
  }

  try {
    const timeConfig = includeTime ? ({ hour: 'numeric', minute: 'numeric' }) as const : {}
    return new Date(datestr).toLocaleString('uk-UA', { year: 'numeric', month: 'long', day: 'numeric', ...timeConfig })
  } catch (e) {
    return 'Проблема з датою'
  }
}

const defaultStudentTableHeaders = [
  { text: '№', value: 'no', sortable: false },
  // { text: 'ID', value: 'id' },
  { text: 'Прізвище Ім\'я', value: 'last_name' },
  { text: 'Контакт', value: 'contact', sort: (a: Student, b: Student) => (a.telegram_id || a.discord_id || a.skype || '').localeCompare(b.telegram_id || b.discord_id || b.skype || '') },
  { text: 'Github', value: 'github_id' },
  { text: 'Linkedin', value: 'linkedin' },
  { text: 'Логи', value: 'logs', sortable: false },
  { text: 'Тип', value: 'type', filterable: true },
  { text: 'Дії', value: 'actions', sortable: false }
]

export const dashboardConfig = {
  studentsTable: [
    {
      text: 'Усі курси',
      value: 'allCoursesStatuses',
      sort: (v1: any, v2: any) => {
        return Object.keys(v1 || {}).filter(k => k !== 'scs').length - Object.keys(v2 || {}).filter(k => k !== 'scs').length
      },
      computedValue (student: Student) {
        const statusesByCourse = student.logs
          .filter(log => log.event === 'course_status_changed')
          .reduce((courses: Record<string, Array<StudentLog>>, l: StudentLog) => ({
            ...courses,
            [l.course || '']: [...(courses[l.course || ''] || []), l]
          }), {})

        return mapValues(statusesByCourse, (courseLogs: Array<StudentLog>) =>
          courseLogs.reduce((acc: any, l: StudentLog) => ({
            ...acc,
            // will be overwritten on each iteration to the later status
            status: l.value,
            // value is course status
            [`${l.value}`]: l
          }), {})
        )
      }
    },
    {
      text: 'Поточний курс',
      value: 'currentCourse',
      width: 100,
      sort (a: {name: string}, b: {name: string}) {
        return a.name.localeCompare(b.name)
      },
      computedValue (student: Student) {
        const [currentCourseName, currentCourseStatus] = student.allCoursesStatuses
          ? Object.entries(student.allCoursesStatuses)
            .filter(([_, course]) => (course.started && !(course.finished || course.stopped || course.dropped || course.paused)) || (course.paused && course.unpaused))
            .last() || ['', {}]
          : ['', {}]

        return { name: currentCourseName, startDate: ((currentCourseStatus.started as StudentLog) || currentCourseStatus.unpaused)?.created_at }
      }
    },
    {
      text: 'Поточний курс (старт)',
      value: 'currentCourseStartDate',
      width: 100,
      computedValue (student: Student) {
        return student.currentCourse?.startDate || ''
      }
    },
    {
      text: 'Знайшов роботу (фірма)',
      value: 'job',
      computedValue (student: Student) {
        return (student.logs.filter(log => log.event === 'job_found').last()?.value as string) || ''
      }
    },
    {
      text: 'Останній апдейт',
      value: 'lastUpdate',
      width: 170,
      computedValue (student: Student) {
        return niceDate(student.logs.last()?.created_at)
      }
    }
  ]
}

function fallbackCopyTextToClipboard (text: string) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
  }

  document.body.removeChild(textArea)
}

export function checkByFilters (expectedValue: any, receivedValue: any) {
  return Array.isArray(expectedValue)
    ? (expectedValue.length ? expectedValue.includes(receivedValue) : true)
    : typeof expectedValue === 'object'
      ? objectCompareDeep(receivedValue, (expectedValue as any))
      : expectedValue !== null
        ? receivedValue === expectedValue
        : receivedValue !== undefined
}

export function objectCompareDeep (objToCompare: any, filterObject: any): boolean {
  if (!objToCompare) {
    return false
  }

  return Object.entries(filterObject).every(([key, expectedValue]: any) => {
    return checkByFilters(expectedValue, objToCompare[key])
  })
}

export function copyTextToClipboard (text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function () {
  }, function () { })
}

export const AdminLogEvents = {
  course_status_changed: {
    text: 'Змінився статус на курсі',
    valueLabel: 'Новий статус',
    value: [
      { text: 'Зарахований на курс', value: 'started' },
      { text: 'Курс пройдено успішно', value: 'finished' },
      { text: 'Курс поставлено на паузу', value: 'paused' },
      { text: 'Відновився після паузи', value: 'unpaused' },
      { text: 'Відраховано з курсу', value: 'dropped' },
      { text: 'Пішов з курсу', value: 'stopped' }
    ]
  },
  admin_communicated: {
    text: 'Просто поспілкувались',
    valueLabel: 'Предмет спілкування'
  },
  job_found: {
    text: 'Знайшов роботу',
    valueLabel: 'Назва фірми'
  },
  report_period_changed: {
    text: 'Змінився період для репортів',
    valueLabel: 'Новий період в днях'
  },
  study_hours_changed: {
    text: 'Змінилась кількість годин',
    valueLabel: 'Нова кількість годин на тиждень'
  }
} as const

export function getReviewsInfo (student: Student, courseId: string = '', level: string, state: StudentsState) {
  const progress = student.logs
    .filter(log => log.course === courseId && log.level === level && log.event === 'progress')
    .map(log => ({ ...log, message: JSON.parse(log.message || '') }))
    .last() || { message: {} }
  const percentage = progress.message.oldProgress + progress.message.diff

  if (percentage < 100) {
    return [{ text: percentage + '%', color: 'grey' }]
  }

  const reviews = student.logs
    .filter(log => log.course === courseId && log.level === level && log.event === 'received_review')
    .map(log => {
      const discordId = ((log.value as any).userId.match(/(discord:)?([0-9]{15,20})/) || [])[2]
      const mentor = Object.entries(state.config.mentors).find(([name, value]: [string, Mentor]) => value.discord === discordId) || []
      const student = state.students.find((student: Student) => student.discord_id === discordId)

      return mentor[0]
        ? { text: mentor[0], color: 'purple' }
        : { text: (student?.first_name + ' ' + student?.last_name), color: 'blue-grey' }
    })

  const daysOld = Math.floor((Date.now() - +new Date(progress.created_at)) / (1000 * 60 * 60 * 24))

  return reviews.length
    ? reviews
    : (percentage
        ? [{ text: `${percentage}% (${daysOld} днів назад)`, color: daysOld > 14 ? 'red' : 'primary' }]
        : [])
}

export function getStudentsReviews (state: StudentsState, courseId: string = '') {
  return getUsersLogs(state).map(student => {
    return {
      ...student,
      reviews: state.config.courses[courseId].levels.reduce((acc, { id }) => ({
        ...acc,
        [id + '-reviews']: getReviewsInfo(student, courseId, id, state)
      }), {})
    }
  })
}
export function isSameWeekYear (date: string, week: number, year: number): boolean {
  if (!date) {
    return false
  }
  const createdAt = new Date(date)
  const createdWeekNumber = getWeekNumber(createdAt)
  return createdAt.getFullYear() === year && createdWeekNumber === week
}

export function getReportsInfo (student: Student, courseId: string, week: string, state: StudentsState): StudentWeeklyReportAnalysis {
  const [targetYear, targetWeek] = week.split('-').map(Number)

  // eslint-disable-next-line camelcase
  const weekLogs = student.logs.filter(({ created_at }) => isSameWeekYear(created_at, targetWeek, targetYear))

  return {
    logs: weekLogs,
    vacation: weekLogs.some(({ event, message }) => event === 'vacation_request_submitted' && isSameWeekYear(message || '', targetWeek, targetYear)),
    totalHours: Math.round(weekLogs.filter(({ event }) => event === 'hours').reduce((acc, { value }) => acc + Number(value), 0)),
    totalReports: weekLogs.filter(({ event }) => event === 'plans').length
  }
}

export function getStudentsReports (state: StudentsState, courseId: string = '') {
  return getUsersLogs(state).map(student => {
    return {
      ...student,
      reports: getWeeksSinceDate(student.logs[0].created_at).reduce((acc, week) => ({
        ...acc,
        [week]: getReportsInfo(student, courseId, week, state)
      }), {})
    }
  })
}

export function selectOptionObject (option: string) {
  return {
    text: option,
    value: option
  }
}

const getWeekNumber = (date: Date) => {
  const d = new Date(date.valueOf())
  const dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  return Math.ceil((((Number(d) - Number(yearStart)) / 86400000) + 1) / 7)
}

const addDays = (initialDate: Date, daysAmount: number) => {
  const date = new Date(initialDate.valueOf())
  date.setDate(date.getDate() + daysAmount)
  return date
}

const getWeekFormatted = (date: Date) => date.getFullYear() + '-' + getWeekNumber(date)

export function getWeeksSinceDate (startDate: string) {
  const firstReportDate = new Date(startDate)
  const weeksSinceFirstReport = Math.ceil((Date.now() - Number(firstReportDate)) / 1000 / 60 / 60 / 24 / 7)

  return [...Array(weeksSinceFirstReport)].map((_, i) => getWeekFormatted(addDays(firstReportDate, 7 * i)))
}

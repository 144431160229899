import api from '@/api'
import { calculateFields, calculateHeaders, dashboardConfig, getAllStartedCourses, getUsersLogs } from '@/utils'
import { ActionContext } from 'vuex'
import { Course, Student, StudentsState, SystemConfig } from '@/types'

export default {
  namespaced: true,
  state: (): StudentsState => ({
    config: {
      dashboardConfig: {
        studentsTable: []
      },
      courses: {},
      vars: {
        vacationFormUrl: ''
      },
      mentors: {}
    },
    students: [],
    studentsLogs: []
  }),
  mutations: {
    init (state: StudentsState, data: StudentsState) {
      state.config = data.config || state.config
      state.students = data.students || state.students
      state.studentsLogs = data.studentsLogs || state.studentsLogs
      state.config.dashboardConfig = dashboardConfig
    },
    config (state: StudentsState, data: SystemConfig) {
      state.config = data || state.config
      state.config.dashboardConfig = dashboardConfig
    }
  },
  getters: {
    studentsWithLogs (state: StudentsState): Student[] {
      return getUsersLogs(state)
    },
    computedData (state: StudentsState, getters: any) {
      return calculateFields(getters.studentsWithLogs, state.config.dashboardConfig.studentsTable)
    },
    computedHeaders (state: StudentsState) {
      return calculateHeaders(state.config.dashboardConfig.studentsTable)
    },
    getCoursesByStudent: (state: StudentsState, getters: any) => (studentId: number) => {
      return getAllStartedCourses(getters.studentsWithLogs.find((s: Student) => s.id === studentId)?.logs)
    },
    rawStudents (state: StudentsState) {
      return state.students
    },
    courses (state: StudentsState): Record<string, Course> {
      return state.config?.courses
    }
  },
  actions: {
    async initData ({ commit }: ActionContext<StudentsState, StudentsState>) {
      const { data } = await api.get('admin/dashboard')
      commit('init', data)
    },
    async getConfig ({ commit }: ActionContext<StudentsState, StudentsState>) {
      const { data } = await api.get('admin/dashboard', { params: { noLogs: 1, noStudents: 1 } })
      commit('config', data.config)
    }
  }
}


import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  methods: {
    ...mapActions('students', ['getConfig'])
  },
  computed: {
    ...mapGetters('students', ['courses'])
  },
  data: () => ({}),
  mounted () {
    this.getConfig()
  }
})


import { copyTextToClipboard } from '@/utils'
import Vue from 'vue'
export default Vue.extend({
  props: {
    text: String
  },
  methods: {
    copyToClipboard () {
      copyTextToClipboard(this.text)
    }
  }
})


import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import FilterableHeader from '@/components/FilterableHeader.vue'
import { checkByFilters } from '@/utils'

export default Vue.extend({
  name: 'StudentsList',
  components: {
    FilterableHeader
  },
  computed: {
    ...mapGetters('students', {
      students: 'computedData',
      headers: 'computedHeaders'
    }),
    studentFiltered () {
      return this.students.filter((student: Student) => {
        return Object.entries((this as any).filters).reduce((acc, [keyToCheck, filter]) => {
          return acc && checkByFilters(filter, (student as any)[keyToCheck])
        }, true)
      })
    }

  },
  data () {
    return {
      search: '',
      filters: {
        type: [],
        allCoursesStatuses: []
      }
    }
  },
  async mounted () {
    if (this.$route.query.filter) {
      try {
        this.filters = JSON.parse(this.$route.query.filter as string || '')
      } catch (e) {
        console.warn("Couldn't parse filters")
      }
    }
    await this.initData()
  },
  methods: {
    ...mapActions('students', ['initData']),
    filterBy (courseName: string, courseStatus: string) {
      const courseConfig = courseStatus ? { status: courseStatus } : {}
      this.$set(this.filters, 'allCoursesStatuses', { [courseName]: courseConfig })
    },
    getRoleColor (role: string) {
      switch (role) {
        case 'postcs':
          return 'error'
        case 'team':
        case 'admin':
          return 'green'
        case 'afterjs':
          return 'blue'
        case 'cs':
        case 'p2pgrad':
          return 'black'
      }
    }
  },
  watch: {
    filters: {
      handler: function (newVal) {
        // do something when the data changes.
        // console.log(JSON.stringify(newVal));
        if (JSON.stringify(newVal) !== this.$route.query.filter) {
          this.$router.replace({ query: { filter: JSON.stringify(newVal) } })
          // this.$route.query.filter = JSON.stringify(newVal);
        }
      },
      deep: true
    }
  }

})

import axios from 'axios'
import { app } from './main'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

let inProgress = 0

instance.interceptors.request.use((config) => {
  app.$Progress.start()
  inProgress++;
  config.params = {
    ...config.params,
    authRedirectUrl: window.location.href
  }

  return config
})

instance.interceptors.response.use((response) => {
  inProgress--
  if (!inProgress) {
    app.$Progress.finish()
  }
  if (response.config.method === 'post') {
    alert('Збережено')
  }
  return response
}, (error) => {
  app.$Progress.fail()
  const redirectUrl = error?.response?.headers?.location || error?.response?.headers?.Location

  if (redirectUrl) {
    window.location = redirectUrl
  }

  return error
})

export default instance

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import StudentsList from '../views/StudentsList.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'students',
    component: StudentsList
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue')
  },
  {
    path: '/reviews/:courseId',
    name: 'reviews',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Reviews.vue')
  },
  {
    path: '/reports/:courseId',
    name: 'reports',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Reports.vue')
  },
  {
    path: '/admin-report',
    name: 'admin-report',
    component: () => import(/* webpackChunkName: "logs" */ '../views/AdminForm.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


import { Student } from '@/types'
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  props: {
    filters: Object,
    headerConfig: Object
  },
  computed: {
    ...mapGetters('students', {
      students: 'computedData'
    })
  },
  data () {
    return {
      empty: {
        text: 'порожні',
        value: ''
      }
    }
  },
  methods: {
    getAllPossibleValues (key: string) {
      return [...new Set(this.students.map((s: Student) => (s as any)[key]))].map((v) => ({
        text: v,
        value: v
      }))
    }
  }

})

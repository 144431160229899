import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import students from './students'
import logs from './logs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    students,
    logs
  },
  plugins: [createLogger()]
})
